<template>
	<a-card>
		<template #title>
			<div class="header">
				<a-button type="primary" @click="backUp" style="margin-right: 10px"> 返回</a-button>
			</div>
		</template>
		<a-form style="width: 640px; margin: 0 auto" ref="merchantRef" :model="ruleForm" :label-col="state.labelCol" :wrapper-col="state.wrapperCol">
			<a-form-item label="应用图标" name="name">
				<a-upload
					style="width: 50px; height: 50px"
					v-model:file-list="file.fileList"
					name="avatar"
					list-type="picture-card"
					class="avatar-uploader"
					:show-upload-list="false"
					action=""
					:before-upload="beforeUpload"
					:custom-request="changeUpload"
				>
					<img v-if="file.imageUrl" style="width: 100%; height: 100%" :src="file.imageUrl" alt="avatar" />
					<div v-else>
						<loading-outlined v-if="file.loading"></loading-outlined>
						<plus-outlined v-else></plus-outlined>
						<div class="ant-upload-text">Upload</div>
					</div>
				</a-upload>
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入应用名称`,
						trigger: 'blur',
					},
				]"
				name="name"
				label="应用名称"
			>
				<a-input v-model:value="ruleForm.name" :placeholder="`请输入应用名称`" />
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入应用类型`,
						trigger: 'blur',
					},
				]"
				name="appType"
				label="应用类型"
			>
				<a-select ref="select" v-model:value="ruleForm.appType" style="width: 100%" :placeholder="`请输入应用类型`" @change="changeAppType">
					<a-select-option v-for="(item, key) in appTypeListMapping" :key="key" :value="key">
						{{ item }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入应用分类`,
						trigger: 'blur',
					},
				]"
				name="appCategory"
				label="应用分类"
			>
				<a-select ref="select" v-model:value="ruleForm.appCategory" style="width: 100%" :placeholder="`请输入应用分类`">
					<a-select-option v-for="item in appTypeList" :key="item.code" :value="item.code">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入code`,
						trigger: 'blur',
					},
				]"
				name="code"
				label="应用编码"
			>
				<a-input v-model:value="ruleForm.code" :placeholder="`请输入code`" />
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入标题`,
						trigger: 'blur',
					},
				]"
				name="title"
				label="标题"
			>
				<a-input v-model:value="ruleForm.title" :placeholder="`请输入标题`" />
			</a-form-item>
			<a-form-item
        v-if="ruleForm.appType === 'SERVICE'"
				:rules="[
					{
						required: true,
						message: `请输入appKey`,
						trigger: 'blur',
					},
				]"
				name="appKey"
				label="appKey"
			>
				<a-input v-model:value="ruleForm.appKey" :placeholder="`请输入appKey`" />
			</a-form-item>
			<a-form-item
        v-if="ruleForm.appType === 'SERVICE'"
				:rules="[
					{
						required: true,
						message: `请输入appSecret`,
						trigger: 'blur',
					},
				]"
				name="appSecret"
				label="appSecret"
			>
				<a-input v-model:value="ruleForm.appSecret" :placeholder="`请输入appSecret`" />
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入平台`,
						trigger: 'blur',
					},
				]"
				name="platform"
				label="平台"
			>
				<a-input v-model:value="ruleForm.platform" :placeholder="`请输入平台`" />
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入baseUrl`,
						trigger: 'blur',
					},
				]"
				name="baseUrl"
				label="baseUrl"
			>
				<a-input v-model:value="ruleForm.baseUrl" addon-before="https://" :placeholder="`请输入baseUrl`" />
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入tags`,
						trigger: 'blur',
					},
				]"
				name="tags"
				label="tags"
			>
				<a-input v-model:value="ruleForm.tags" :placeholder="`请输入tags`" />
			</a-form-item>
			<a-form-item
				:rules="[
					{
						required: true,
						message: `请输入desc`,
						trigger: 'blur',
					},
				]"
				name="desc"
				label="desc"
			>
				<a-input v-model:value="ruleForm.desc" :placeholder="`请输入desc`" />
			</a-form-item>
			<a-form-item
				v-if="ruleForm.appType && ruleForm.appType !== 'SERVICE'"
				:rules="[
					{
						required: true,
						message: ruleForm.appType === 'PRODUCT' ? '请选择服务' : '请选择产品',
						trigger: 'change',
					},
				]"
				name="subAppList"
				:label="ruleForm.appType === 'PRODUCT' ? '服务' : '产品'"
			>
				<a-select
					v-model:value="ruleForm.subAppList"
					:options="viewOptions"
					label-in-value
					mode="multiple"
					:placeholder="ruleForm.appType === 'PRODUCT' ? '请选择服务' : '请选择产品'"
					style="width: 100%"
					@change="changeSubAppList"
				></a-select>
			</a-form-item>
			<a-form-item :wrapper-col="{ span: 14, offset: 8 }">
				<a-button type="primary" style="margin: 15px auto" @click="submitAdd('submit')">{{ edit ? "保存" : "创建" }}</a-button>
			</a-form-item>
		</a-form>
	</a-card>
</template>
<script setup>
import { onMounted, ref, computed } from "vue"
import { useRouter } from "vue-router"
import { message } from "ant-design-vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"

const store = useStore()
const router = useRouter()
const state = ref({
	labelCol: { span: 4 },
	wrapperCol: { span: 14 },
})
const appTypeList = ref()
const viewOptions = ref([])
const edit = ref(false)
onMounted(() => {
	if (history.state?.json) {
		ruleForm.value = JSON.parse(history.state.json)
		edit.value = true
	}
	appTypeList.value = JSON.parse(localStorage.getItem("appTypeList"))?.filter((item) => item.code)
})
const ruleForm = ref({
	code: undefined, //编码
	name: undefined, //名称
	appCategory: undefined, //分类
	title: undefined, //标题
	appKey: undefined, //appKey
	appSecret: undefined, //appSecret
	platform: undefined, //平台
	baseUrl: undefined, //url
	icon: undefined, //图标
	tags: undefined, //标签
	desc: undefined, //描述
	subAppList: [], //服务
})
const merchantRef = ref()
const appTypeListMapping = computed(() => store.state.appTypeListMapping)
const submitAdd = () => {
	const data = {
		app: {
			id: ruleForm.value.id,
			code: ruleForm.value.code, //编码
			name: ruleForm.value.name, //名称
			appType: ruleForm.value.appType, //分类
			appCategory: ruleForm.value.appCategory, //分类
			title: ruleForm.value.title, //标题
			appKey: ruleForm.value.appKey, //appKey
			syncMerchant: +(ruleForm.value.appType === "SERVICE"), //是否同步商户
			appSecret: ruleForm.value.appSecret, //appSecret
			platform: ruleForm.value.platform, //平台
			baseUrl: `https://${ruleForm.value.baseUrl}`, //url
			icon: ruleForm.value.icon, //图标
			tags: ruleForm.value.tags, //标签
			desc: ruleForm.value.desc, //描述
			subAppList: ruleForm.value.subAppList.map((item) => ({
				subAppCode: item.value,
				subAppType: item.option.appType,
			})),
		},
	}
	merchantRef.value
		.validateFields()
		.then((res) => {
			if (res) {
				axios.post(api.appSaveTemplate, data).then(() => {
					message.success("操作成功")
					router.push({ name: "addApplication" })
				})
			}
		})
		.catch((err) => {
			console.log(err)
		})
}
const backUp = () => {
	router.back()
}

//上传功能
const beforeUpload = (file) => {
	const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"

	if (!isJpgOrPng) {
		message.error("You can only upload JPG file!")
	}

	const isLt2M = file.size / 1024 / 1024 < 2

	if (!isLt2M) {
		message.error("Image must smaller than 2MB!")
	}

	return isJpgOrPng && isLt2M
}
const getBase64 = (img, callback) => {
	const reader = new FileReader()
	reader.addEventListener("load", () => callback(reader.result))
	reader.readAsDataURL(img)
}
const file = ref({
	fileList: [],
	loading: false,
	imageUrl: "",
})
const changeUpload = (info) => {
	file.value.loading = true
	const formData = new FormData()
	// Get this url from response in real world.
	getBase64(info.file, (base64Url) => {
		file.value.imageUrl = base64Url
		file.value.loading = false
		formData.append("file", info.file)
		formData.append("merchantId", store.getters["user/merchantId"])
		formData.append("bizType", "APP_ICON")
		axios
			.post(api.fileUpload, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				ruleForm.value.icon = res.url
			})
	})
}
const getOptionsList = (type) => {
	axios
		.post(api.getAppList, {
			appTypeList: [type],
		})
		.then((res) => {
			viewOptions.value = res.list.map((item) => ({
				value: item.code,
				label: item.title || item.name,
				appType: item.appType,
			}))
		})
}

const changeSubAppList = (val) => {
	console.log(val, ruleForm.value.subAppList)
}

const changeAppType = (val) => {
	ruleForm.value.subAppList = []
	if (val === "PRODUCT") {
		getOptionsList("SERVICE")
	} else if (val === "PRODUCT_SUITE") {
		getOptionsList("PRODUCT")
	}
	getAppType(val)
}

const getAppType = (appType) => {
	axios
		.post(api.appCategoryList, {
			appType,
		})
		.then((res) => {
			appTypeList.value = res.filter((item) => item.code)
		})
}
</script>
